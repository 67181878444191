import React from "react";
import Navbar from "../Components/Navbar";
import HeroSection from "../Components/HeroSection";
import Services from "../Components/Services";
import Insurance from "../Components/Insurance";

function Home() {
  return (
    <>
      <Navbar />
      <HeroSection />
      <Services />
      <Insurance/>
    </>
  );
}

export default Home;
