import React from "react";
import "./Navbar.css";
import logo from "../assets/logo.png";

const Navbar = () => {
  return (
    <nav className="navbar">

      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
        <span>BankFincon</span>
        <span id="tagline">Driving Success, Building Trust</span>
        
      </div>


      <button className="navbar-toggler"  >
        ☰
      </button>
      <div className="navbar-links" id="navbarLinks">
        <a href="/home"  ><b>Home</b></a>
        <a href="/about">About</a>
        <a href="/services">Services</a>
        <a href="/contact" className="skyblue-button ">Contact</a>
      </div>
    </nav>
    
  );
};

export default Navbar;
