import React from "react";
import tickbox from "../assets/icons/tickbox.png";
import "./HeroSection.css";
import heroImage from "../assets/heroImage.png"

function HeroSection() {
  return (
    <>
      <div className="container">
        <div className="left-half">
          <br />
          <br />
          <br />
          <h5 id="sticker">Finance</h5><br />
          <br />
          <h1 id="hero-line">Streamlined and <span id="highlighted">Improved</span> Financial Navigation</h1>
          <br /><br /><br />
          <p id="hero-para">
            Simplify and optimize your financial management with our
            user-friendly solutions, paving the way for a prosperous and secure
            future.
          </p>
          <br />
          <br />
          <div className="tickbox-container">
            <span id="hero-tick">
              <img src={tickbox} className="tickbox" alt="" />
              Trusted
            </span>
            <span id="hero-tick">
              <img src={tickbox} className="tickbox" alt="" />
              Credible
            </span>
            <span id="hero-tick">
              <img src={tickbox} className="tickbox" alt="" />
              Secure
            </span>
            <br />
            <hr className="line"/>
            <p id="fainted">We provide services to companies all over PAN-India.</p>
          </div>
        </div>
        <div className="right-half">
            <img src={heroImage} className="heroImage"alt="" />
        </div>
      </div>
    </>
  );
}

export default HeroSection;
