import React from 'react'
import "./Insurance.css";
import Card from './Card';
import "./Card.css";
import './Services.css'
import houseLoan from "../assets/icons/houseLoan.png";
import propertyAgainstLoan from "../assets/icons/loanAgainstProperty.png";
import personalLoan from "../assets/icons/personalLoan.png";
import InsuranceImage from "../assets/icons/insuranceImage.png";
import protect from "../assets/icons/Protect.png";
import carLoan from "../assets/icons/carLoan.png";
import designText from "../assets/designText.png";

function Insurance() {
  return (
    <>
    <h1 id='left-marg'>Insurance Plan<span>
            <img src={protect} alt="" />
          </span></h1>
    <p id='left-marg'>Protect What Matters Most - Comprehensive Insurance Solutions for Peace of Mind!</p>
    <div className="container">
    <div className="left-half">
        <center>
    <div className="holder">
    <div className="service-catalogue-one">
            <Card
              image={houseLoan}
              title="Home Insurance"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
            <Card
              image={personalLoan}
              title="Medical Insurance"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
            
          </div>
          </div></center>
          <center>
          <div className="holder" id='lower-margin'>
          
    <div className="service-catalogue-one" >
    <Card
              image={propertyAgainstLoan}
              title="Home Insurance"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
            <Card
              image={carLoan}
              title="Car Insurance"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
          </div>
          </div></center>
    </div>
    <div className="right-half" id='position'>
        <img src={InsuranceImage} className='heroImage' alt="" />
        <img src={designText} alt="" id='design-img'/>
    </div>

</div>
    </>
  )
}

export default Insurance
