import React from 'react'

import "./Card.css"
function Card(prop) {
  return (
   <>
   <div className="outerbox">
    <center>
     <img id="coordinate" src={prop.image} alt="" />
     <br /><br />
            <h3 id='coordinate'>{prop.title}</h3>

            <p id='coordinate'>{prop.desc} </p>
         
            <button>Learn More+</button>
            </center>   </div>
   </>
  )
}

export default Card
