import React from "react";
import cash from "../assets/icons/Cash.png";
import "./Services.css";
import Card from "./Card";
import houseLoan from "../assets/icons/houseLoan.png";
import propertyAgainstLoan from "../assets/icons/loanAgainstProperty.png";
import personalLoan from "../assets/icons/personalLoan.png";
import goldLoan from "../assets/icons/goldLoan.png";
import bussinessLoan from "../assets/icons/bussinessLoan.png";
import carLoan from "../assets/icons/carLoan.png";
import educationLoan from "../assets/icons/educationLoan.png";
import creditCard from "../assets/icons/creditCard.png";

function Services() {
  return (
    <>
      <div className="service-container">
        <h1 id="left-marg">
          Our Services
          <span>
            <img src={cash} alt="" />
          </span>
        </h1>
        <p id="left-marg">We provide wide range of Loans and Insurance for our Customer’s</p><br />
        <center>
        <div className="holder">

          <div className="service-catalogue-one">
            <Card
              image={houseLoan}
              title="Home Loan"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
            <Card
              image={personalLoan}
              title="Home Loan"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
            <Card
              image={propertyAgainstLoan}
              title="Home Loan"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
            <Card
              image={carLoan}
              title="Home Loan"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
          </div>
          <div className="service-catalogue-two">
            <Card
              image={educationLoan}
              title="Home Loan"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
            <Card
              image={goldLoan}
              title="Home Loan"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
            <Card
              image={bussinessLoan}
              title="Home Loan"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
            <Card
              image={creditCard}
              title="Home Loan"
              desc="Hello World this is DescriptionHello World this is DescriptionHello World this is Description"
            />
          </div>
          <button className="btn-view-more">Learn More+</button>
        </div>
        
        </center>
      </div>
      
    </>
  );
}

export default Services;
